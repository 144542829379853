import Sdata from "./Sdata";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Header.css";
import { useEffect } from "react";
import { DiHtml5 } from "react-icons/di";
import { FaCss3Alt } from "react-icons/fa";
import { SiJavascript } from "react-icons/si";
import { FaSass } from "react-icons/fa";
import { SiReact } from "react-icons/si";
import { TbBrandNextjs } from "react-icons/tb";
import { GiIndianPalace } from "react-icons/gi";

const Header = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  });

  return (
    <>
      <header id="header">
        <div className="container header__container">
          <div className="header__profile" data-aos="fade-in">
            <img className="profile-img" src="/images/profile.png" alt="" />
            <div className="verified">
              <img className="verified-img" src="/images/verified.png" alt="" />
            </div>
          </div>
          <div className="header__skills">
            <DiHtml5 />
            <FaCss3Alt />
            <SiJavascript />
            <FaSass />
            <SiReact />
            <TbBrandNextjs />
          </div>
          <h3 data-aos="fade-up">Prashant Bhardwaj</h3>
          <p data-aos="fade-up">
            A highly skilled Frontend Developer based in India{" "}
            <GiIndianPalace size={16} className="tajmahal" /> with a strong
            passion for creating exceptional digital experiences
          </p>
          <div className="header__cta">
            <a href="#contact" className="btn primary">
              Let's Talk
            </a>
            <a href="#portfolio" className="btn light">
              My Work
            </a>
          </div>
          <div className="header__socials">
            {Sdata.map((item) => (
              <a key={item.id} href={item.link} target="_blank" rel="noopener noreferrer">
                {item.icon}
              </a>
            ))}
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
