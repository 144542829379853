import { createContext, useContext, useReducer, useEffect } from "react";
import ThemeReducer from "./ThemeReducer";

export const ThemeContext = createContext();

const initialThemeState = JSON.parse(localStorage.getItem("themeSettings")) || {
  primary: "color-1",
  background: "bg-1",
};

export const ThemeProvider = ({ children }) => {
  const [themeState, dispatchTheme] = useReducer(
    ThemeReducer,
    initialThemeState
  );

  const themeHandler = (buttonClassName) => {
    dispatchTheme({
      type: buttonClassName,
    });
  };

  // Save Theme Settings to Local Storage
  useEffect(() => {
    localStorage.setItem("themeSettings", JSON.stringify(themeState));
  }, [themeState.primary, themeState.background]);

  return (
    <ThemeContext.Provider value={{ themeState, themeHandler }}>
      {children}
    </ThemeContext.Provider>
  );
};

// Custom hook to use our theme context wherever we want in our project

export const useThemeContext = () => {
  return useContext(ThemeContext);
};
