import "./About.css";
import { HiDownload } from "react-icons/hi";
import Card from "../../components/Card";
import Adata from "./Adata";

const About = () => {
  return (
    <>
      <section id="about" data-aos="fade-in">
        <div className="container about__container">
          <div className="about__left">
            <div className="about__portrait">
              <img src="/images/about-img.png" alt="" />
            </div>
          </div>
          <div className="about__right">
            <h2>About me</h2>
            <div className="about__cards">
              {Adata.map((item) => (
                <Card key={item.id} className="about__card">
                  <span className="about__card-icon">{item.icon}</span>
                  <h5>{item.title}</h5>
                  <small>{item.desc}</small>
                </Card>
              ))}
            </div>
            <p>
              Over my 2+ years in the web development industry, I have
              undertaken a diverse range of projects, spanning from small,
              simple websites to large, complex ones. I have consistently
              delivered top-notch projects within the given timeframe, and this
              has fueled my motivation to take on even more challenging
              assignments.
            </p>
            <p>
              Greetings, I am Prashant Bhardwaj, a dedicated Frontend Developer
              from India with a Bachelor's degree in Computer Science. My main
              goal is to help you establish a strong online presence through
              industry-standard design and comprehensive functionality, enabling
              you to operate seamlessly in the digital space. Don't hesitate to
              contact me for any inquiries or to discuss your project further.
              Please find my resume attached below for your reference.
            </p>
            <a
              href="/Prashant-Bhardwaj-Resume.pdf"
              download
              className="btn primary"
            >
              Download CV <HiDownload />
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
