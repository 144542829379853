import "./Navbar.css";
import { IoIosColorPalette } from "react-icons/io";
import { useModalContext } from "../../context/ModalContext";
import Ndata from "./Ndata";

const Navbar = () => {
  const { showModalHandler } = useModalContext();

  return (
    <>
      <nav>
        <div className="container nav__container">
          <a className="nav__logo" href="index.html">
            Geeky <span>Prashant</span>
          </a>
          <ul className="nav__menu">
            {Ndata.map((item) => (
              <li key={item.id}>
                <a href={item.link}>{item.title}</a>
              </li>
            ))}
          </ul>
          <button id="theme__icon" onClick={showModalHandler}>
            <IoIosColorPalette />
          </button>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
