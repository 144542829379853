const Pdata = [
  {
    id: 1,
    category: "Website",
    image: "/images/responsive-nike-clone.png",
    title: "Responsive Nike Clone",
    desc: "The project is built using HTML, CSS, and JavaScript.",
    demo: "https://responsive-nike-clone.vercel.app/",
    github: "https://github.com/geeky-prashant/responsive-nike-clone",
  },
  {
    id: 2,
    category: "Website",
    image: "/images/responsive-react-mui-social-media-website.png",
    title: "React Responsive Social Media Website",
    desc: "The project is built using HTML, CSS, JavaScript, React 18, Material UI, and Vercel.",
    demo: "https://react-responsive-social-media-website.vercel.app/",
    github:
      "https://github.com/geeky-prashant/react-responsive-social-media-website",
  },
  {
    id: 3,
    category: "Website",
    image: "/images/react-responsive-restaurant-website.png",
    title: "React Responsive Restaurant Website",
    desc: "The project is built using HTML, CSS, JavaScript, React 18, React Router, Styled Components, Vite and Vercel.",
    demo: "https://react-responsive-restaurant-website.vercel.app/",
    github:
      "https://github.com/geeky-prashant/react-responsive-restaurant-website",
  },
  {
    id: 4,
    category: "Website",
    image: "/images/react-responsive-ecommerce-website.png",
    title: "React Responsive Sneakers Store",
    desc: "The project is built using HTML, CSS, SCSS, JavaScript, React 18, React Router, and Vercel.",
    demo: "https://react-responsive-ecommerce-website-pi.vercel.app/",
    github:
      "https://github.com/geeky-prashant/react-responsive-ecommerce-website",
  },
  {
    id: 5,
    category: "Web App",
    image: "/images/nextjs-responsive-user-dashboard.png",
    title: "NextJS Responsive User Dashboard",
    desc: "The project is built using HTML, Tailwind CSS, JavaScript, Next.js 13, React.js 18, Chart.js, and Vercel.",
    demo: "https://nextjs-user-dashboard.vercel.app/",
    github:
      "https://github.com/geeky-prashant/nextjs-responsive-user-dashboard",
  },
  {
    id: 6,
    category: "Web App",
    image: "/images/react-responsive-google-search-clone.png",
    title: "React Responsive Google Search Clone",
    desc: "The project is built using HTML, Tailwind CSS, JavaScript, React.js 18, React Router, Axios, and Vercel.",
    demo: "https://react-responsive-google-search-clone.vercel.app/",
    github:
      "https://github.com/geeky-prashant/react-responsive-google-search-clone",
  },
  {
    id: 7,
    category: "Web App",
    image: "/images/react-responsive-youtube-clone.png",
    title: "React Responsive YouTube Clone",
    desc: "The project is built using HTML, Tailwind CSS, JavaScript, React.js 18, React Router, Axios, Rapid API and Vercel.",
    demo: "https://react-responsive-youtube-clone.vercel.app/",
    github: "https://github.com/geeky-prashant/react-responsive-youtube-clone",
  },
  {
    id: 8,
    category: "Mobile App",
    image: "/images/react-native-food-delivery-app.png",
    title: "React Native Food Delivery App",
    desc: "The project is built using Expo, React Native, and React Native Navigation.",
    demo: "https://github.com/geeky-prashant/react-native-food-delivery-app",
    github: "https://github.com/geeky-prashant/react-native-food-delivery-app",
  },
  {
    id: 9,
    category: "Mobile App",
    image: "/images/react-native-fitness-app.png",
    title: "React Native Fitness App",
    desc: "The project is built using Expo, React Native, and React Native Navigation.",
    demo: "https://github.com/geeky-prashant/react-native-fitness-app",
    github: "https://github.com/geeky-prashant/react-native-fitness-app",
  },
  {
    id: 10,
    category: "Mobile App",
    image: "/images/react-native-movie-app.png",
    title: "React Native Movie App",
    desc: "The project is built using Expo, React Native, React Native Navigation, NativeWind, Axios, and TMDB Movie API.",
    demo: "https://github.com/geeky-prashant/react-native-movie-app",
    github: "https://github.com/geeky-prashant/react-native-movie-app",
  },
  {
    id: 11,
    category: "Mobile App",
    image: "/images/react-native-weather-app.png",
    title: "React Native Weather App",
    desc: "The project is built using Expo, React Native Navigation, NativeWind, HeroIcons, and WeatherAPI.",
    demo: "https://github.com/geeky-prashant/react-native-weather-app",
    github: "https://github.com/geeky-prashant/react-native-weather-app",
  },
  {
    id: 12,
    category: "Mobile App",
    image: "/images/react-native-linkedIn-clone.png",
    title: "React Native LinkedIn Clone",
    desc: "The project is built using React Native Expo CLI, React Native Navigation and JavaScript.",
    demo: "https://github.com/geeky-prashant/react-native-linkedin-clone",
    github: "https://github.com/geeky-prashant/react-native-linkedin-clone",
  },
];

export default Pdata;
