import React from "react";
import Navbar from "./sections/Navbar/Navbar";
import Header from "./sections/Header/Header";
import About from "./sections/About/About";
import Skills from "./sections/Skills/Skills";
import Portfolio from "./sections/Portfolio/Portfolio";
import Testimonials from "./sections/Testimonials/Testimonials";
import FAQs from "./sections/FAQs/FAQs";
import Contact from "./sections/Contact/Contact";
import Footer from "./sections/Footer/Footer";
import FloatingNav from "./sections/FloatingNav/FloatingNav";
import Theme from "./theme/Theme";
import { useThemeContext } from "./context/ThemeContext";
import ReactGA from "react-ga4";

const App = () => {
  const TRACKING_ID = "G-JFMRZ35HB5";
  ReactGA.initialize(TRACKING_ID);
  const { themeState } = useThemeContext();
  return (
    <>
      <main className={`${themeState.primary} ${themeState.background}`}>
        <Navbar />
        <Header />
        <About />
        <Skills />
        <Portfolio />
        <Testimonials />
        {/* <FAQs /> */}
        <Contact />
        <Footer />
        <FloatingNav />
        <Theme />
      </main>
    </>
  );
};

export default App;
